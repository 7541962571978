// Listing
#module-prestashop_visual_composer-blog {
  overflow-x: hidden;

  .blog-listing {
    margin-bottom: 50px;

    .flow-bg {
      background-image: url("../img/flow-full.png");
      background-repeat: no-repeat;
      background-position: calc(100% + 40px) 0;
      width: 472px;
      height: 886px;
      position: absolute;
      right: -5px;
      top: -38px;
      opacity: .25;
    }

    .blog-listing-description {
      margin-bottom: 50px;

      p {
        font-size: 1.25rem;
        font-weight: $font-bold;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .introduction {
      margin-bottom: 30px;

      p {
        font-size: 1.25rem;
        font-weight: $font-bold;
      }
    }
  }
}

// Detail
#module-prestashop_visual_composer-blog_post {

  .blog-detail {

    .date-published {
      font-size: .875rem;
      color: $grey-text;
    }

    .categories {
      margin-bottom: 30px;
      font-size: .875rem;
      color: $grey-text;

      a {
        color: $primary;

        &:hover,
        &:focus,
        &:active {
          color: $primary-hover;
        }
      }
    }

    img.news {
      float: none;

      @include media-breakpoint-mobile {
        margin-bottom: 20px;
      }
    }

    .prestablogExtra {
      border: 0;
      padding: 0;
    }

    .prestablog-featured-products {
      margin-top: 35px;
    }

    .img-thumbnail {
      padding: 0;
      background-color: transparent;
      border: 0;
      box-shadow: none;
    }

    .card-product {

      .card-img-top {

        img {
          display: block;
          margin-left: auto;
          margin-right: auto;
          width: auto;
          max-height: 295px;
        }
      }
    }
  }
}
