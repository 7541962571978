#module-solina_commons-newsletter {

  #content-wrapper {
    margin-bottom: 50px;
  }

  .section-title {

    @include media-breakpoint-mobile {
      margin-top: 30px;
    }
  }

  #mc_embed_signup {
    margin: auto;
    background: $white;
    clear: left;
    font-family: $font-family-main;
    max-width: 540px;

    .submit-row {
      text-align: center;
    }

    form {
      padding: 0;
    }

    label {
      font-size: .875rem;
      color: $grey-text;
      margin-bottom: .2rem;
      padding-bottom: 0;
      font-weight: $font-regular;

      &:not(:last-of-type) {
        margin-right: 30px;
      }
    }

    h2 {
      font-weight: $font-regular;
      padding: 0 0 10px 0;
      margin: 0 0 .5rem 0;
      font-size: 3.5rem;

      @include media-breakpoint-mobile {
        font-size: 2.8rem;
      }
    }

    #mc-embedded-subscribe-form {

      div.mce_inline_error {
        padding-left: 0;
      }
    }

    input[type="submit"] {
      margin: auto;
      border: 1px solid $secondary;

      &:hover,
      &:focus,
      &:active {
        border: 1px solid $secondary-hover;
      }
    }

    input[type="email"] {
      border: 1px solid $gray-400;
    }

    div#mce-responses {
      padding: 0;
      margin: 0 0 20px 0;
      width: 100%;
    }
  }

  #mc-embedded-subscribe-form input[type=checkbox] {
    display: inline;
    width: auto;
    margin-right: 10px;
  }

  #mergeRow-gdpr {
    margin-top: 20px;
  }

  .checkbox-style fieldset label {
    font-weight: normal;
  }

  #mc-embedded-subscribe-form .mc_fieldset{
    border:none;
    min-height: 0;
    padding-bottom:0;
  }
}
