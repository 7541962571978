.share-wrapper {
  position: relative;

  .share-buttons {
    position: absolute;
    top: calc(100% + 10px);
    right: 0;
    background-color: $white;
    padding: 10px;
    border-radius: 5px;
    box-shadow: $card-hover-box-shadow;
    visibility: hidden;
    opacity: 0;
    transform: translateY(-5px);
    transition: opacity $anim-speed $anim-curve, transform $anim-speed $anim-curve;

    &.displayed {
      visibility: visible;
      opacity: 1;
      transform: translateY(0);
    }
  }

  .btn-share {
    border: 0;
    border-radius: 2px;
    background-color: $primary;
    color: $white;
    font-size: .75rem;
    padding: 2px 5px;
  }
}

