h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-family-headings;
}

.main-title-page {
  font-family: $font-family-headings;
  font-size: 3.5rem;
  letter-spacing: -.37px;
  margin: 45px 0 30px 0;
  text-transform: none;

  @include media-breakpoint-mobile {
    font-size: 2.8rem;
  }

  &.small-title {
    font-size: 3rem;

    @include media-breakpoint-mobile {
      font-size: 2.5rem;
    }
  }
}

.banner-title {
  margin-bottom: 40px;
  text-transform: none;
  font-size: 3.5rem;
  color: $white;
  letter-spacing: $letter-spacing-titles;
  line-height: 1.15;

  @include media-breakpoint-mobile {
    font-size: 2.8rem;
  }
}

.section-title {
  display: flex;
  justify-content: center;
  margin-bottom: 50px;

  .text {
    position: relative;
    padding-bottom: 20px;
    font-size: 3.5rem;
    letter-spacing: $letter-spacing-titles;
    line-height: 1.15;
    text-transform: none;

    &::after {
      content: "";
      position: absolute;
      width: 225px;
      height: 2px;
      background-color: $essential-yellow;
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
    }

    @include media-breakpoint-mobile {
      font-size: 2.8rem;
    }
  }
}

.content-surtitle {
  color: $essential-brown;
  font-size: 1.125rem;
  text-transform: uppercase;
  letter-spacing: $letter-spacing-surtitles;
  margin-bottom: 10px;

  @include media-breakpoint-mobile {
    font-size: 1rem;
  }
}

.content-title {
  line-height: 1.15;
  font-size: 2.875rem;
  letter-spacing: $letter-spacing-titles;
  margin-bottom: 30px;
  text-transform: none;

  @include media-breakpoint-mobile {
    font-size: 2rem;
  }

  &.title-small {
    font-size: 2rem;
  }
}

.card-title {
  margin-bottom: 20px;
  font-size: 1.625rem;

  @include media-breakpoint-mobile {
    font-size: 1.25rem;
  }

  a {
    color: $black;
  }
}

.card-subtitle {
  @extend .very-small-title;
}

.very-small-title {
  font-family: $font-family-main;
  font-weight: $font-bold;
  font-size: 1.25rem;
  margin-bottom: 15px;

  @include media-breakpoint-mobile {
    font-size: 1.1rem;
  }
}
