.wysiwyg-content {

  a {
    color: $secondary;
    font-weight: 400;
    text-decoration: underline;

    &:hover {
      color: $secondary-hover;
    }

    &.btn-default {
      text-decoration: none;
      @extend .btn-secondary;
    }
  }

  > * {

    &:last-child {
      margin-bottom: 0;
    }
  }

  h1 {
    font-size: 2.2rem;
  }

  h2 {
    font-size: 1.8rem;
  }

  h3 {
    font-size: 1.4rem;
  }

  h4 {
    font-size: 1.3rem;
  }

  h5 {
    font-size: 1.2rem;
  }

  h6 {
    font-size: 1.1rem;
  }

  p {
    font-size: 1rem;
    font-weight: $font-regular;
  }

  ul,
  ol {
    font-weight: 400;
    padding-left: 20px;
  }

  ul {
    list-style: disc;
  }
}
