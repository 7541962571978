.custom-select {

  &:focus {
    border: 1px solid transparent;
  }
}

.custom-control-input {

  &:checked {

    & ~ .custom-control-label {

      &::before {
        background-color: $essential-brown;
        border-color: $essential-brown;
      }
    }
  }
}
