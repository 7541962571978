.slider-products-recipes {

  .carousel,
  .slick-list,
  .slick-track {
    height: 100%;
  }

  .carousel {

    &.carousel-left-position {

      @include media-breakpoint-desktop {

        .slick-arrow {
          right: -73px;
          left: inherit;
          top: 70%;
        }
      }
    }
  }

  .slick-arrow {
    position: absolute;
    width: 43px;
    height: 43px;
    top: 50%;
    left: -58px;
    color: $primary;
    transition: background-color $anim-speed $anim-curve;

    &.slick-prev {
      background-color: rgba($white, .5);
      transform: translateY(-50%) translateY(-23px);

      @include media-breakpoint-mobile {
        left: 15px;
        top: 40%;
        transform: translateY(-50%);
      }

      svg {
        transform: rotate(180deg);
      }
    }

    &.slick-next {
      transform: translateY(-50%) translateY(23px);
      background-color: $white;

      @include media-breakpoint-mobile {
        right: 15px;
        left: auto;
        top: 40%;
        transform: translateY(-50%);

      }
    }

    &:hover,
    &:focus,
    &:active {
      @extend .outline-white;
      background-color: $white;
    }
  }

  .product-miniature,
  .recipe-miniature {
    margin-bottom: 0;
  }

  article.product-miniature,
  article.recipe-miniature {
    flex: auto;
    max-width: none;
  }

  .carousel {
    margin-left: -15px;
    margin-right: -15px;
  }

  .slick-track {
    margin-left: unset;
    margin-right: unset;
  }

  .col-card-colored {

    @include media-breakpoint-mobile {
      order: 2;
    }
  }

  .col-slider {

    @include media-breakpoint-mobile {
      order: 1;
    }
  }

  &.slider-card-right {
    padding-top: 0;

    .col-card-colored {
      order: 2;
    }

    .slick-track {

      @include media-breakpoint-desktop {
        margin-left: auto;
      }
    }

    .slick-arrow {
      right: -58px;
      left: unset;

      &.slick-prev {

        @include media-breakpoint-mobile {
          left: 15px;
          right: auto;
        }
      }

      &.slick-next {

        @include media-breakpoint-mobile {
          right: 15px;
          left: auto;
        }
      }
    }
  }
}
