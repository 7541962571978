// Thumbs
.product-thumbs {
  width: calc(100% - 85px) !important;
  margin: 20px auto 0 !important;
  height: auto !important;

  &.slick__arrow-outside {

    button {
      color: $tertiary;
      top: 50%;
    }

    .slick-next {
      transform: translate(50px, -50%);
    }

    .slick-prev {
      transform: translate(-50px, -50%) rotate(180deg);
    }
  }

  .slick-slide > div {
    cursor: pointer;
  }

  &:not(.slick-initialized) > div:not(:first-child) {
    display: none;
  }

  .product-thumb {
    padding: $product-thumb-margin;
    border: 2px solid transparent;
    transition: border $anim-speed $anim-curve;
    height: 100%;
    border: 4px solid transparent;
    margin: 0;

    & > div {
      border: 1px solid $grey-border;
      height: 100%;
      display: flex;
    }

    .thumb {
      margin: auto;
    }
  }

  .slick-current {

    & > div {
     height: 100%;
    }

    .product-thumb {
      padding-top: 5px;

      & > div {
        padding: 10px 0;
        border: 1px solid $grey-border;
      }
    }
  }

  .slick-track {
    display: flex;
    align-items: center;
  }
}
