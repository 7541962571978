.novius-blog-detail {

  .main-title-page {
    font-size: 3rem;
  }

  .introduction {
    margin-bottom: 30px;
    font-size: 1.25rem;
    font-weight: $font-bold;
  }

  .wysiwyg-content {

    h1 {
      font-size: 1.6rem;
    }

    h2 {
      font-size: 1.4rem;
    }

    h3 {
      font-size: 1.2rem;
    }

    h4 {
      font-size: 1.1rem;
    }

    h5 {
      font-size: 1.1rem;
    }

    h6 {
      font-size: 1.1rem;
    }
  }
}
