.contact-rich {
  color: $grey-text;
  margin-bottom: 2rem;
  word-wrap: break-word;

  .content-surtitle {
    line-height: 1.25;
  }

  .block {
    display: flex;
    align-items: flex-start;

    .icon {
      width: 2rem;

      i {
        font-size: 1.25rem;
      }
    }

    .data {
      color: $grey-text-dark;
      font-size: .812rem;
      width: auto;
      overflow: hidden;
    }

    a[href^="mailto:"] {
      clear: both;
      margin-top: 1rem;

      @include media-breakpoint-up(sm) {
        font-size: .815rem;
      }
    }
  }
}

.contact-form {
  background: white;
  padding: 1rem;
  color: $grey-text;
  width: 100%;

  h3 {
    text-transform: uppercase;
    color: $grey-text-dark;
  }
}
