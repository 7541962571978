.products {
  @extend .row;
  align-items:stretch;
}

.product-miniature,
.recipe-miniature {
  @extend .col-6;

  .featured-category &,
  .featured-recipes & {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

@include media-breakpoint-desktop {

  .product-miniature,
  .recipe-miniature {
    @include make-col(4);
  }

  .layout-full-width .product-miniature,
  .layout-full-width .recipe-miniature {
    @include make-col(3);
  }
}

@media (max-width: 320px) {
  .product-miniature,
  .recipe-miniature {
    @include make-col(12);
  }
}

.slick-slide .product-miniature,
.slick-slide .recipe-miniature {
  max-width: 100%;
}

/* Product miniature*/
.card-product {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  background-color: $white;
  transition: box-shadow $anim-speed $anim-curve;

  &:hover {
    box-shadow: $card-hover-box-shadow;

    .highlighted-informations {
      transform:translateY(0);
    }
  }

  &.recipe {

    .product-title {
      margin-bottom: 0;

      a {
        font-size: 1.5rem;
      }
    }
  }

  .discount {
    display:none;
  }

  .shop-now-cta {
    text-align: center;
  }
}

.card-img-top {
  overflow: hidden;

  .product-thumbnail img {
    width: 100%;
    transition: transform $anim-speed $anim-curve;

    .card-product:hover & {
      transform: scale(1.05);
    }
  }
}

.highlighted-informations {
  display: none;
  position: absolute;
  bottom: 0;
  width:100%;
  background-color: $pm-highlight-bg-color;
  transform:translateY(100%);
  transition:transform .3s;

  .card-product:hover & {
    transform:translateY($pm-highlight-translateY);
  }
}

.quick-view {
  position: absolute;
  padding: 15px;
  top: 0;
  right: 0;
  cursor: pointer;
  opacity: 0;
  transition: opacity $anim-speed $anim-curve;

  .card-product:hover & {
    opacity: 1;
  }
}

.product-flags {
  position: absolute;
  bottom: 0;
  margin-bottom: 0;
  z-index: 1;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.page-content--product .product-flags {
  left:0;
}

.product-flag {
  margin-top: $spacer/2;
  padding: .5rem 1.5rem;
  color: $white;
  background-color: $color-flag-new;

  &.discount-product{
    background-color: $color-flag-promo;
  }
}

.color {
  width: 1.25rem;
  height: 1.25rem;
  display: inline-block;
  box-shadow: 2px 2px 4px 0 rgba(0,0,0,.2);
  border: 1px solid rgba($black, .05);
  cursor: pointer;
  background-size: contain;
}

.product-title {
  text-transform: lowercase;
  font-family: $font-family-headings;
  color: $black;
  font-size: 1.25rem;
  text-decoration: none;

  &::first-letter {
    text-transform: uppercase;
  }

  & a {
    color: $black;
    text-transform: lowercase;

    &::first-letter {
      text-transform: uppercase;
    }
  }
}

/* PRODUCT PAGE */
.products-imagescover {
  box-shadow: $card-box-shadow;;
}

.product-img:not(:first-child) .rc {
  display: none;
}

.slick-initialized .product-img .rc {
  display: block;
}

.product-thumbs {
  width: $product-thumb-wrap-width;
  height: $product-thumb-wrap-height;
  margin:0 auto;
  &[data-count="2"]{
    width:$product-thumb-wrap-width-2;
  }
  .slick-slide > div{
    cursor: pointer;

  }
  &:not(.slick-initialized) > div:not(:first-child){
    display: none;
  }

}
.product-thumb{
  margin: $product-thumb-margin;
  width:$product-thumb-width;
  height:$product-thumb-height;
  border:2px solid transparent;
  &.slick-current {
    border: 2px solid theme-color('primary');
  }
}

.btn-zoom {
  color: $primary;
  position: absolute;
  right: $spacer;
  bottom: $spacer;
  cursor: pointer;
  display: none;
  padding: 0;
  line-height: 1;

  & .material-icons{
    font-size: 3*$font-size-base;
  }
}

.images-container:hover .btn-zoom{
  display: none;
}

.label-color{
  line-height: 1;
  margin-right: $spacer/2;
}
.product-variants-item .color{
  width:2rem;
  height:2rem;
  border: 2px solid transparent;
}

.input-color:checked+span, .input-color:hover+span, .input-radio:checked+span, .input-radio:hover+span {
  border-color: $colorbox-border-color;
}

//product prices
.product__product-price.has-discount{
  .current-price{
    display: inline-block;
  }
}
.price{
  color:$product-price-current-color;
  font-size:$product-price-current-font-size;
  font-weight:$product-price-current-font-weight;
  font-family: $product-price-current-font-family;
  &.current-price-discount{
    color:$product-price-discount-color;
    font-size:$product-price-discount-font-size;
    font-weight:$product-price-discount-font-weight;
    font-family: $product-price-discount-font-family;
  }
}
.regular-price{
  color:$product-price-regular-color;
  font-size:$product-price-regular-font-size;
  font-weight:$product-price-regular-font-weight;
  font-family: $product-price-regular-font-family;
  text-decoration: line-through;
}
.discount{
  text-transform: uppercase;
  color: #FFF;
  background-color: $product-price-discount-color;
  font-weight: 600;
  display: inline-block;
  padding: .25*$spacer;
}

//product add to cart
.btn-add-to-cart{
  //@extend .d-flex;
  text-transform: uppercase;
  font-weight: 600;
  white-space: nowrap;
}

//product tabs
.product-tabs{
  .nav-tabs{
    font-size: $font-size-base * .9;
  }
}
.card--reassurance{
  box-shadow:$card-box-shadow;;
}

// modal add to cart
.cart-content{
  padding: $spacer;
  background: theme-color('light');
}

//product pack
.pack-product-container{
  @extend .d-flex;
  @extend .justify-content-between;
  @extend .align-items-center;
  padding: 0 $spacer;
  font-size: $font-size-sm;
}
.mask{
  width: 55px;
}
.thumb-mask{
  padding: $spacer 0;
}
.pack-product__img{
  border: 1px solid $border-color;
}
.pack-product-name{
  @extend .flex-grow-1;
  @extend .mx-4;
}
.pack-product-quantity{
  border-left:1px solid $border-color;
}
.pack-product-price{
  padding-right: $spacer/2;

}
.pack-product-quantity{
  @extend .d-flex;
  @extend .align-items-center;
  @extend .align-self-stretch;
  padding-left: $spacer;
}

//modal cart
.modal-cart__image{
  margin-right: $spacer;
  max-width: 200px;

  height: auto;
}
.modal-cart__name{
  color: $primary;
  font-weight: $font-weight-bold;
}
.product-total{
  font-weight: $font-weight-bold;
}
.cart-content{
  > p:not(:last-child){
    margin-bottom: $spacer/2;
  }
}
@include media-breakpoint-mobile {
  .modal-cart__image{
    max-width: 120px;
  }
  .blockcart-modal {
    .divide-right{
      display: flex;
      justify-content: center;
    }
    .modal-footer {
      position: fixed;
      bottom: 0;
      background: #fff;
      justify-content: center;
      left: 0;
      width: 100%;
      box-shadow: 0px -0.25rem 0.5rem rgba($black, .15);
    }
    .modal-body{
      margin-bottom: ($btn-padding-y * 2) + ($btn-font-size*$btn-line-height) + $modal-inner-padding * 2;
    }
  }
}
@include media-breakpoint-down(xs) {
  .modal-cart__image{
    max-width: 70px;
  }
}
//spinner add to cart
.btn-add-to-cart {
  .btn-add-to-cart__spinner {
    @extend .spinner-border;
    width: $spinner-width-addtocart;
    height: $spinner-height-addtocart;
    border-width: $spinner-borderwidth-addtocart;
    display: none;
  }
  &.is--loading{
    .btn-add-to-cart__spinner {
      display: inline-block;
    }
    .btn-add-to-cart__icon {
      display: none;
    }
  }
}

.products-selection {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
  padding: 10px;
  background-color: $white;

  @include media-breakpoint-mobile {
    margin-top: 20px;
    background-color: transparent;
    padding: 0;
  }

  .total-products {

    @include media-breakpoint-mobile {
      width: 100%;
    }

    .product__quantity {
      margin-right: 15px;
      margin-bottom: 0;
      font-size: .875rem;
      color: $grey-text;

      @include media-breakpoint-mobile {
        margin-right: 0;
        margin-bottom: 15px;
        text-align: right;
      }
    }
  }

  .sort-by-row {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .btn-filters {
      margin-left: 10px;
    }
  }
}

.pack-miniature-item{
  margin-bottom: $spacer / 2;
}
.ratio1_1{
  display: block;
}
.pack-product-name{
  margin: 0 $spacer * 1.5;
  flex-grow: 1;
}
.product-miniature,
.recipe-miniature {
  margin-bottom: $spacer ;
}
.product__card-img{
  position: relative;
}
.product__card-desc{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.highlighted-informations{
  text-align: center;
  padding: $spacer / 2;
}
.quantity__label{
  margin-bottom: 0;
  margin-top: $spacer;

}
.product-add-to-cart{
  .product-quantity{
    margin-top: -($spacer);
  }
  .qty{
    margin: 0 $spacer;
    margin-top: $spacer;
  }
  .add{
    margin-top: $spacer;
  }
}

.product-customization {
  margin-bottom: $spacer * 1.5;
}

.product-discounts {
  margin-bottom: $spacer * 1.5;
}

.total__item-show{
  text-align: center;
  font-size: $font-size-sm;
  margin-top: $spacer;
}
.variant-links{
  display: flex;
  justify-content: center;
}
.product__download{
  text-transform: uppercase;
}
.card--address{
  height: 100%;
  text-align: center;
  justify-content: center;
}
.invoice__address{
  margin-top: $spacer;
}
.invoice-address{
  height: 100%;
  text-align: center;
  justify-content: center;
}
.nav-tabs{
  justify-content: center;
}
