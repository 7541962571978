.social-sharing {
  @extend .d-flex;
  @extend .flex-wrap;

  ul {
    margin-bottom: 0;
  }

  li {
    padding: 0 5px;

    &:not(:last-of-type) {
      border-right: 1px solid rgba($white, .2);
    }
  }
}

.social-sharing--product {
  margin: $spacer 0;
  @extend .align-items-center;
}

.modal .social-sharing--product {
  margin: 0;
}

.social-share-btn {
  width: 28px;
  height: 28px;
  display: block;
  background-repeat: no-repeat;
  background-position: center center;

  &.facebook {
    background-image: url(../img/facebook.svg);
    background-size: 90%;
  }

  &.twitter {
    background-image: url(../img/twitter.svg);
  }

  &.linkedin {
    background-image: url(../img/linkedin.svg);
    background-size: 65%;
  }

  &.pinterest {
    background-image: url(../img/pinterest.svg);
  }

  &.rss {
    background-image: url(../img/rss.svg);
  }

  &.youtube {
    background-image: url(../img/youtube.svg);
  }

  &.vimeo {
    background-image: url(../img/vimeo.svg);
  }

  &.instagram {
    background-image: url(../img/instagram.svg);
    background-size: 90%;
  }
}

.social-share-btn--product {
  background-size:80%;
  background-color: #fff;
  @extend .shadow-sm;
  border-radius: 50%;
  transition: box-shadow 0.3s ease-in-out;

  &:hover {
    box-shadow:$card-box-shadow;;
  }

  &.facebook {
    background-image: url(../img/facebook-gray.svg);

    &:hover {
    background-image: url(../img/facebook-blue.svg);
    }
  }

  &.twitter {
    background-image: url(../img/twitter-gray.svg);

    &:hover {
      background-image: url(../img/twitter-blue.svg);
    }
  }

  &.googleplus {
    background-image: url(../img/gplus-gray.svg);

    &:hover {
      background-image: url(../img/gplus-blue.svg);
    }
  }

  &.pinterest {
    background-image: url(../img/pinterest-gray.svg);

    &:hover {
      background-image: url(../img/pinterest-blue.svg);
    }
  }
}

.social-share-btn--footer {
  border-bottom: 1px solid transparent;
  transition: border-bottom $anim-speed $anim-curve;

  &:hover {
    border-bottom: 1px solid $white;
  }
}
