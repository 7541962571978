.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

a.outline,
button.outline,
input.outline,
select.outline {

  &:not(.disabled):not(:disabled) {

    &:not(.no-box-shadow) {

      &:focus {
        outline: none;
        box-shadow: 0 0 0 2px rgba($color-main, .25);
      }

      &.active,
      &:active {
        box-shadow: 0 0 0 2px rgba($color-main, .25);

        &:focus {
          outline: none;
          box-shadow: 0 0 0 2px rgba($color-main, .5);
        }
      }
    }
  }

  &.outline-primary {

    &:not(.disabled):not(:disabled) {

      &:not(.no-box-shadow) {

        &:focus {
          outline: none;
          box-shadow: 0 0 0 2px rgba($primary, .25);
        }

        &.active,
        &:active {
          box-shadow: 0 0 0 2px rgba($primary, .25);

          &:focus {
            outline: none;
            box-shadow: 0 0 0 2px rgba($primary, .5);
          }
        }
      }
    }
  }

  &.outline-secondary {

    &:not(.disabled):not(:disabled) {

      &:not(.no-box-shadow) {

        &:focus {
          outline: none;
          box-shadow: 0 0 0 2px rgba($secondary, .25);
        }

        &.active,
        &:active {
          box-shadow: 0 0 0 2px rgba($secondary, .25);

          &:focus {
            outline: none;
            box-shadow: 0 0 0 2px rgba($secondary, .5);
          }
        }
      }
    }
  }

  &.outline-white {

    &:not(.disabled):not(:disabled) {

      &:not(.no-box-shadow) {

        &:focus {
          outline: none;
          box-shadow: 0 0 0 2px rgba($white, .25);
        }

        &.active,
        &:active {
          box-shadow: 0 0 0 2px rgba($white, .25);

          &:focus {
            outline: none;
            box-shadow: 0 0 0 2px rgba($white, .5);
          }
        }
      }
    }
  }
}
