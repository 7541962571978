#pagenotfound {

  #content-wrapper {
    margin-bottom: 50px;
  }

  .page-wrapper {
    border: 0;
    background-color: transparent;
  }

  .main-title-page {
    margin: 90px 0 30px;
  }

  .page-content {
    padding: 0;

    #search_widget {

      .close-row {
        display: none !important;
      }
    }
  }
}
