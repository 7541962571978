.card-blog-item {
  display: flex;
  flex-direction: column;
  background-color: $white;
  height: 100%;
  transition: box-shadow $anim-speed $anim-curve, background-color $anim-speed $anim-curve;

  &:hover {
    box-shadow: $card-hover-box-shadow;
    background-color: $white;
  }

  .image {

    img {
      width: 100%;
      height: 320px;
      object-fit: cover;

      @include media-breakpoint-down(lg) {
        height: 250px;
      }
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    padding: 30px 15px 45px;
  }

  .cta {
    margin-top: auto;
  }
}
