.l-header {
  position: relative;
  background-color: $header-bg;
  top: 0;
  z-index: $zindex-sticky;
  border-bottom: 1px solid transparent;
  transition: top $anim-speed $anim-curve, box-shadow $anim-speed $anim-curve, border-bottom $anim-speed $anim-curve;
  border-bottom: 1px solid $grey-bg-dark;

  &.is-stuck {
    position: sticky;
    top: 0;

    @include media-breakpoint-mobile {
      position: relative;
      top: unset;
    }
  }

  &.header-relative {
    position: relative;
  }

  &.border-bottom {
    border-bottom: 1px solid $grey-border;
  }

  .logo {
    display: flex;
    align-items: center;

    a {
      padding: 5px;
      display: block;
      color: $color-main;
    }

    svg {

      @include media-breakpoint-mobile {
        width: 125px;
      }
    }
  }

  .a-pinch-of-apollo {
    display: none;
  }

  .header-top {
    background-color: $white;
  }

  .header-content {
    display: flex;
    flex-wrap: wrap;
    padding-top: $spacer*1.25;

    @include media-breakpoint-mobile {
      padding-top: 5px;
      padding-bottom: 5px;
    }
  }

  .menu {
    margin-left: auto;

    @include media-breakpoint-mobile {
      display: flex;
    }
  }

  .top {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 30px;
    padding-bottom: 5px;
    border-bottom: 1px solid $grey-border;

    @include media-breakpoint-mobile {
      display: none;
    }

    .displayNav1 {
      display: flex;
      align-items: center;
    }

    .displayNav2 {
      display: flex;
      flex-wrap: wrap;
      margin-left: auto;

      .brochure-link,
      .contact-link {
        margin-left: 10px;

        a {
          font-size: .875rem;
        }
      }
    }

    .displayTop {
      display: none;
    }
  }

  .bottom {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .navigation {

      @include media-breakpoint-mobile {
        display: none;
      }
    }

    .search-trigger {
      border-left: 1px solid rgba($grey-border, .5);
      margin-bottom: 12px;
      margin-left: 15px;
      padding-left: 15px;

      @include media-breakpoint-mobile {
        border-left: 0;
        padding-left: 0;
        margin-right: 5px;
        margin-bottom: 0;
      }

      .btn {
        color: $secondary;
      }
    }
  }

  .hamburger {
    color: $secondary;
  }
}

div.mobile-menu-modal {

  .top-menu-mobile {
    margin-bottom: 20px;
  }

  .language-selector-wrapper {
    margin: 30px 0;

    .dropdown-toggle {
      margin: auto;
      border: 1px solid $primary
    }
  }

  .contact-link {
    margin-bottom: 5px;
  }

  .btn-header {
    justify-content: center;
    background-color: $primary;
    border: 1px solid $primary;
    color: $white;
    font-size: 14px;

    &:hover,
    &:focus,
    &:active {
      background-color: $primary-hover;
      border: 1px solid $primary-hover;
    }

    svg {
      width: 16px;
      color: $white;
    }
  }

  .logo {

    a {
      display: block;
      margin-top: 30px;
      text-align: center;
      color: $color-main;
    }
  }
}

ul.ui-autocomplete {
  padding: 0;
  background: none;
  z-index: $zindex-sticky;
  border: 0;
  box-shadow: 0 2px 0 2px rgba($secondary, .25);

  @include media-breakpoint-down(xs) {
    max-width: 290px;
  }

  .ui-menu-item {
    background: $white;

    a {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px;
      transition: background $anim-speed $anim-curve;
      border-radius: 0;
      cursor: pointer;

      @include media-breakpoint-down(xs) {
        flex-direction: column;
        align-items: flex-start;
      }

      &.ui-state-focus {
        border: 0;
        background: $secondary;
        color: $white;

        .category {
          color: $white;
          border: 1px solid $white;

          @include media-breakpoint-down(xs) {
            border: 0;
          }
        }
      }

      .separator {
        display: none;
      }

      .product {
        order: 1;
        font-size: 1rem;
      }

      .category {
        display: block;
        order: 2;
        padding: 5px;
        font-size: .75rem;
        color: $secondary;
        border: 1px solid $secondary;
        transition: color $anim-speed $anim-curve, border $anim-speed $anim-curve;

        @include media-breakpoint-down(xs) {
          padding: 0;
          border: 0;
        }
      }
    }
  }
}
