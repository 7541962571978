ul.nav-tabs {
  justify-content: flex-start;
  border-bottom: 1px solid $grey-border;

  @include media-breakpoint-mobile {
    flex-direction: column;
    border-bottom: 0;
  }

  .nav-link {
    color: $black;
    font-weight: $font-regular;
    transition: none;

    @include media-breakpoint-mobile {
      background-color: transparent;
      border: 1px solid $black;
      text-align: center;
    }

    &:hover,
    &:focus {

      @include media-breakpoint-mobile {
        background-color: $grey-bg;
        border: 1px solid $black;
      }
    }

    &.active {
      color: $primary;
      font-weight: $font-bold;
      border-color: transparent;
      background-color: transparent;

      @include media-breakpoint-mobile {
        color: $white;
        background-color: $primary;
        border: 1px solid $primary;
      }

      &::after {
        content: "";
        position: absolute;
        display: block;
        top: calc(100% - 5px);
        left: 0;
        width: 100%;
        height: 3px;
        background-color: $primary;

        @include media-breakpoint-mobile {
          display: none;
        }
      }
    }
  }

  .nav-item {
    position: relative;

    @include media-breakpoint-mobile {
      margin-bottom: 5px;
    }
  }
}
