@font-face {
  font-family: 'Playfair Display';
  src: url('../fonts/PlayfairDisplay-Regular.woff2') format('woff2'),
  url('../fonts/PlayfairDisplay-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto-Regular.woff2') format('woff2'),
  url('../fonts/Roboto-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto-Bold.woff2') format('woff2'),
  url('../fonts/Roboto-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(~material-design-icons/iconfont/MaterialIcons-Regular.eot); /* For IE6-8 */
  src: local('Material Icons'),
  local('MaterialIcons-Regular'),
  url(~material-design-icons/iconfont/MaterialIcons-Regular.woff2) format('woff2'),
  url(~material-design-icons/iconfont/MaterialIcons-Regular.woff) format('woff'),
  url(~material-design-icons/iconfont/MaterialIcons-Regular.ttf) format('truetype');
}
