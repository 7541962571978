.modal {

  .modal-content {
    background-color: $white;
    box-shadow: 0 .5rem 1rem rgba($black, .15);
  }

  &.product-modal-shopping {

    .modal-content {
      background-color: $essential-blue;
      color: $white;
      padding: 30px;

      @include media-breakpoint-mobile {
        padding: 15px;
      }

      .modal-header {
        border-bottom: 0;

        .close {
          background-color: $secondary;
          color: $white;
          opacity: 1;
          width: 36px;
          height: 36px;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0;
        }
      }

      .modal-title {
        font-size: 2.875rem;
        font-family: $font-family-headings;

        @include media-breakpoint-mobile {
          font-size: 2rem;
        }
      }

      .modal-description{
        line-height: 26px;
      }

      .modal-cta-container {
        margin: 20px 0;
      }
    }
  }
}

.modal-submenu-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: $black;
  opacity: 0;
  visibility: hidden;
  transition: opacity $anim-speed $anim-curve;
  z-index: $zindex-sticky - 1;

  &.active {
    visibility: visible;
    opacity: .5;
  }
}
