#index {

  .page-home {
    overflow: hidden;
  }

  .slider-products-recipes.slider-card-right,
  .slider-products-recipes:nth-of-type(2n) {
    padding-top: 55px;
  }
}
