.pagination-wrapper {

  .page-item {
    margin: 0 3px;

    &.active {

      .page-link {
        color: $white;
        background-color: $primary;
      }
    }

    .page-link {
      color: $primary;
      background-color: transparent;
      border: 0;

      &.next,
      &.prev {
        padding: .5rem .405rem;
      }
    }
  }

  .page-link:focus,
  .product-comments__pagination li span:focus {
    box-shadow: 0 0 0 2px rgba($primary, .25);
  }
}
