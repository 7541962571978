.page-recipe {

  .heading {
    background-image: $gradient-dark;
    position: relative;
    min-height: 580px;

    @include media-breakpoint-down(xl) {
      min-height: 520px;
    }

    @include media-breakpoint-down(lg) {
      min-height: 350px;
    }

    @include media-breakpoint-down(md) {
      min-height: auto;
    }

    .flow {
      background-image: url("../img/flow-right-full.png");
      background-repeat: no-repeat;
      position: absolute;
      width: 100%;
      height: 100%;
      background-position: right bottom;
      background-size: contain;
    }

    .image-container {
      width: 100%;
      height: 100%;
      position: relative;
      display: flex;
      justify-content: center;
    }

    .main-image {
      width: 100%;
      position: absolute;
      max-width: 610px;
      max-height: 540px;
      object-fit: cover;

      @include media-breakpoint-down(lg) {
        max-height: 400px;
      }

      @include media-breakpoint-down(md) {
        position: relative;
        max-width: unset;
      }
    }

    &__content {
      color: $white;
      padding: 20px 0;

      @include media-breakpoint-down(md) {
        margin-top: 30px;
      }

      .btn-icon {
        background-color: $white;
        border-radius: 100%;
        width: 36px;
        height: 36px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 0;

        @include media-breakpoint-mobile {
          width: 44px;
          height: 44px;
        }
      }

      .recipe-title {
        text-transform: lowercase;
        font-family: $font-family-headings;
        font-size: 2.875rem;
        letter-spacing: -.5px;

        &::first-letter {
          text-transform: uppercase;
        }
      }

      .recipe-type {
        font-family: $font-family-headings;
        font-size: 1.625rem;
      }

      .recipe-our-products {
        border-top: 1px solid $white;

        .recipe-product,
        .btn-show-more {
          color: $white;
          text-decoration: underline;
          font-size: .875rem;
        }

        .recipe-product {
          display: inline-flex;

          a {
            color: inherit;
            text-transform: lowercase;
            display: inline-block;

            &::first-letter {
              text-transform: uppercase;
            }
          }
        }

        ul {
          margin-left: 17px;

          @include media-breakpoint-desktop {
            column-count: 2;
          }
        }

        li {
          position: relative;
          padding-right: 30px;

          &::before {
            content: "-";
            position: absolute;
            left: -15px;
            top: 0;
          }
        }

        .show-more-wrapper {

          @include media-breakpoint-desktop {
            padding-left: calc(50% + 15px);
          }
        }

        .show-more {
          padding: 5px 0;
          color: $white;
          background-color: transparent;
          border: 0;
          border-bottom: 1px solid $white;
          text-transform: uppercase;
          font-size: .875rem;
        }
      }
    }
  }

  .btn-show-more {
    text-transform: uppercase;
    font-weight: 600;
    color: $black;
    font-size: .75rem;
  }

  .recipes-instructions {
    padding-top: 55px;
    background-color: $white;
    margin-bottom: 40px;

    @include media-breakpoint-down(md) {
      padding: 0;
    }

    .ingredients-container {
      border-right: 1px solid $tertiary;

      @include media-breakpoint-down(md) {
        border-right: 0;
      }
    }

    .recipe-ingredients {
      padding-right: 40px;


      @include media-breakpoint-down(md) {
        padding-right: 0;
      }
    }

    .recipes-preparation {
      padding-left: 40px;

      @include media-breakpoint-down(md) {
        padding-left: 0;
      }
    }

    .ingredients-list {
      color: $grey-text;

      p {
        text-transform: uppercase;
        margin-bottom: 10px;
      }

      ul {

        li {
          margin-bottom: 10px;
        }
      }
    }

    .title-container {
      border-bottom: 1px solid $yellow;
      padding: 10px 0;
      margin-bottom: 30px;

      @include media-breakpoint-down(md) {
        margin-top: 20px;
      }
    }

    .title {
      font-weight: 700;
      font-size: 1.25rem;
    }

    .cooking-duration {
      margin-left: auto;

      @include media-breakpoint-mobile {
        margin-left: 0;
        margin-top: 10px;
      }
    }

    .cooking-time {
      margin-bottom: 0;
    }

    .preparation,
    .legal-disclaimer {
      color: $grey-text;

      ol {
        padding-inline-start: 20px;
        margin-bottom: 30px;

        li {
          margin-bottom: 10px;

          &::marker {
            font-weight: 700;
          }
        }
      }
    }

    .legal-disclaimer {
      font-style: italic;
    }
  }

  .other-recipes {
    position: relative;
    background-color: $grey-bg;
    padding: 50px 0;

    .other-recipes-flow {
      position: absolute;
      background-image: url("../img/flow-full-left.png");
      background-position: top left;
      width: 472px;
      height: 100%;
      left: -130px;
      top: 0;
    }
  }
}

#module-solina-recipe_listing {
  background-color: $grey-bg;

  .recipes {
    margin-left: -15px;
    margin-right: -15px;
  }

  .mobile-filters-toggler {
    display: none;

    @include media-breakpoint-mobile {
      display: flex;
      justify-content: flex-end;
      margin-top: 20px;
    }
  }

  .banner-with-image  {
    background-position: 50% 75%;
  }

  .recipes-listing {
    margin-top: 20px;

    @include media-breakpoint-desktop {
      margin-top: 0;
    }
  }

  .recipe-list-top {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: wrap;
    padding: 10px;
    background-color: $white;

    @include media-breakpoint-mobile {
      margin-top: 20px;
      background-color: transparent;
      padding: 0;
    }

    .total-products {

      @include media-breakpoint-mobile {
        width: 100%;
      }

      .product__quantity {
        margin-right: 15px;
        margin-bottom: 0;
        font-size: .875rem;
        color: $grey-text;

        @include media-breakpoint-mobile {
          margin-right: 0;
          margin-bottom: 15px;
          text-align: right;
        }
      }
    }

    .sort-by-row {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .btn-filters {
        margin-left: 10px;
      }
    }
  }

  .card-recipe {
    background-color: $white;
    height: 100%;
    transition: box-shadow $anim-speed $anim-curve;

    &:hover {
      box-shadow: $card-hover-box-shadow;
    }

    img {
      width: 100%;
      max-width: 100%;
    }

    .card-title {
      text-transform: lowercase;
      font-size: 1.25rem;
      font-family: $font-family-headings;
      letter-spacing: -.17px;
      color: $black;
      padding: 1.25rem;

      &::first-letter {
        text-transform: uppercase;
      }
    }
  }

  #offcanvas_search_filter {

    .modal-body {

      #_desktop_search_filters_wrapper {
        display: unset;
      }
    }
  }
}
