@media print  {

  .l-header {

    .menu {
      display: none;
    }

    .header-logo {
      text-decoration: none;
    }

    .a-pinch-of-apollo {
      display: block;
      font-weight: $font-bold;
      text-align: center;
    }
  }

  footer {
    display: none;
  }

  .navigation{
    display: none;
  }

  .page-recipe {

    .heading {
      background-image: none;

      .flow {
        display: none;
      }
    }

    .heading__content {
      color: $black;

      .recipe-our-products {

        .recipe-product {
          color: $black;
        }
      }
    }

    .recipe-boutons {
      display: none !important;
    }

    .other-recipes {
      display: none;
    }
  }
}
