.supermenu {
  margin-left: -15px;

  ul.depth-0 {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 0;

    > li {

      &:hover,
      &:focus,
      &:active {

        > .submenu-wrapper {
          opacity: 1;
          visibility: visible;
          transform: translateX(0);
        }
      }

      &:not(:last-of-type) {

        > a,
        .first-level-entry {

          &:after {
            content: "•";
            margin-left: 15px;
            font-weight: $font-bold;
            color: $tertiary;
          }
        }
      }

      &:last-of-type {

        > a,
        .first-level-entry {

          @include media-breakpoint-desktop {
            padding: 5px 15px;
          }
        }
      }

      > a,
      .first-level-entry {
        display: flex;
        padding: 5px 0 1.25rem 15px;
        color: $color-main;
        text-transform: uppercase;
        font-weight: $font-bold;
        font-size: .875rem;
        cursor: pointer;
        transition: color $anim-speed $anim-curve;

        @include media-breakpoint-down(lg) {
          font-size: .875rem;
        }

        .underline {
          display: block;
          border-bottom: 1px solid transparent;
          transition: border-bottom $anim-speed $anim-curve;
        }

        &:hover,
        &:focus,
        &:active {
          outline: none;
          color: $essential-brown;

          .underline {
            border-bottom: 1px solid $essential-brown;
          }
        }
      }

      > .submenu-wrapper {
        opacity: 0;
        transform: translateX(-20px);
        visibility: hidden;
      }
    }
  }

  @include media-breakpoint-mobile {
    display: block;
    margin-left: 0;

    .submenu-wrapper {
      width: 100%;
    }

    .has-grand-children {

      ul.depth-2 {
        padding: 0;

        > li {

          &:not(:last-of-type) {
            margin-bottom: 0;
          }
        }
      }
    }

    ul.depth-0 {
      flex-direction: column;

      > li {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        padding: 0;
        border-bottom: 1px solid $grey-border;

        &:not(:last-of-type) {
          margin-right: 0;
        }

        > a,
        > .first-level-entry {
          display: flex;
          align-items: center;
          padding: 1.25rem;
          font-size: .812rem;
          width: calc(100% - 40px);
          min-height: 30px;

          &::after {
            display: none;
          }

          &:hover,
          &:focus,
          &:active {

            .underline {
              border-bottom: 0;
            }
          }

          &.menu-item-link-nosubmenu {
            width: 100%;
          }

          > .underline {
            border-bottom: 0;
          }
        }

        > .submenu-wrapper {
          position: relative;
          visibility: visible;
          opacity: 1;
          transform: translateY(0);
          box-shadow: none;
          top: 0;
          border-top: 0;
          transition: height $anim-speed $anim-curve;
        }
      }
    }

    .has-grand-children {

      ul.depth-1 {

        > li {
          width: 100%;
          margin-bottom: 0;
        }
      }
    }

    ul.depth-1 {
      display: block;
      padding: 0;

      > li {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        padding: 0;
        margin-bottom: 0;
        border-top: 1px solid $grey-border;
        border-right: 0;
        border-bottom: 0;
        width: 100%;

        > a {
          padding: 1rem 1rem 1rem 2rem;
          font-size: .812rem;
          width: calc(100% - 40px);
        }

        > .submenu-wrapper {
          width: 100%;
        }
      }
    }

    ul.depth-2 {
      padding: 0;

      > li {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        padding: 0;
        margin-bottom: 0;
        border-top: 1px solid $grey-border;
        border-right: 0;
        border-bottom: 0;
        width: 100%;

        &:not(:last-of-type) {
          margin-bottom: 0;
        }

        > a {
          padding: 1rem 1rem 1rem 3rem;
          font-size: .812rem;
          width: calc(100% - 40px);
        }
      }
    }

    > .submenu-wrapper {
      transition: all $anim-speed $anim-curve;
    }

    .navbar-toggler {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 30px;
      cursor: pointer;
      color: $primary;
    }

    .icon-collapse {
      transform: rotate(90deg);

      &[aria-expanded=true] {
        transform: rotate(-90deg);
        color: $secondary;
      }
    }
  }
}
