#module-solina_videos-video {

  .video-container {
    max-width: 1005px;
    margin: 50px auto;

    iframe {
      width: 100%;
      aspect-ratio: 16 / 9;
    }

    .wysiwyg-content {
      margin-top: 15px;
      margin-bottom: 15px;
    }
  }

  .recipes {
    background-color: $grey-bg;
  }

  .slider-products-recipes .section-title h2 {
    font-size: 2.5rem;
  }
}
