// Colors
$black: #000;
$grey-text-dark: #1b2437;
$grey-border-dark: #5a5a5a;
$grey-text: #5a5c65;
$grey-text-light: #8c9aa3;
$grey-border: #d2d2d2;
$grey-bg-dark: #eceded;
$grey-bg: #f6f7f8;
$white: #fff;

$color-main: #1d1d1b;
$primary: #796b60;
$primary-hover: darken($primary, 10%);
$secondary: #55717c;
$secondary-hover: darken($secondary, 10%);
$tertiary: #abb2b7;

$pro-blue: #b4c7d3;
$pro-brown: #c9b29e;
$yellow: #e3cc81;

$essential-blue: #3f4b54;
$essential-brown: #976f2b;
$essential-brown-hover: darken($essential-brown, 10%);
$essential-yellow: #e3cb7d;
$essential-grey: #575756;

$color-flag-new: #df8d02;
$color-flag-promo: #df5102;

// Gradients
$gradient-dark: linear-gradient(148deg, $color-main 0%, $primary 42%, $secondary 100%);
$gradient-dark-horizontal: linear-gradient(0deg, $color-main 0%, $secondary 42%, $primary 100%);
$gradient-light: linear-gradient(180deg, $primary 0%, $secondary 100%);

// Fonts
$font-family-main: 'Roboto';
$font-family-headings: 'Playfair Display';

$font-regular: 400;
$font-semi-bold: 600;
$font-bold: 700;

$letter-spacing-read-more: -0.1px;
$letter-spacing-titles: -0.35px;
$letter-spacing-surtitles: 1.8px;

// Transitions
$anim-speed: .3s;
$anim-speed-slow: .5s;
$anim-curve: cubic-bezier(.5, 0, .5, 1);
$anim-curve-links: cubic-bezier(.075,.82,.165,1);

// Z-indexes
$zindex-dropdown: 1000;
$zindex-sticky: 1020;
$zindex-fixed: 1030;
$zindex-modal-backdrop: 1040;
$zindex-modal: 1050;
$zindex-popover: 1060;
$zindex-tooltip: 1070;

// Box-shadows
$card-hover-box-shadow: 0 16px 24px rgba(96, 97, 112, 0.16), 0 2px 8px rgba(40, 41, 61, 0.04);
$btn-box-shadow: 0 2px 4px rgba(96, 97, 112, 0.16), 0 0 1px rgba(40, 41, 61, .04);

// Breakpoints
$grid-breakpoints: (
  xs: 568px,
  sm: 667px,
  md: 768px,
  lg: 992px,
  xl: 1350px,
);
