.header-search-bar {
  position: absolute;
  width: 100%;
  padding: 30px 0 50px 0;
  top: -100%;
  background-color: $white;
  opacity: 0;
  visibility: hidden;
  box-shadow: 0 .5rem 1rem rgba($black, .15);
  transition: opacity $anim-speed $anim-curve, top $anim-speed $anim-curve;
  z-index: -1;

  &.is-opened {
    top: calc(100% + 1px);
    opacity: 1;
    visibility: visible;
  }
}

.search-widget {
  margin: 0 auto;

  .close-row {
    width: 100%;
    margin-bottom: 10px;

    .close-btn {
      padding: 10px;
      background-color: $secondary;
      color: $white;

      &:hover {
        background-color: $secondary-hover;
      }
    }
  }

  form {
    position: relative;
  }

  .input-search {
    width: 100%;
    padding: 10px 70px 10px 0;
    border: 0;
    border-bottom: 1px solid $grey-border;
    font-size: 2rem;
    transition: padding $anim-speed $anim-curve;

    @include media-breakpoint-mobile {
      font-size: 1.25rem;
    }

    &:focus {
      padding: 10px 70px 10px 20px;
      border-bottom: 1px solid transparent;
    }

    &::placeholder {
      color: $grey-border;
      font-weight: $font-bold;
    }
  }

  .btn-submit {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    color: $grey-border;

    @include media-breakpoint-mobile {

      svg {
        width: 20px;
        height: 20px;
      }
    }
  }

  .aa-SubmitButton {

    svg {
      color: $primary;
    }
  }

  .aa-Label {
    margin-bottom: 0;
  }

  .aa-Form {

    &:focus-within {
      border-color: $primary;
      box-shadow: rgba($primary, var(--aa-primary-color-alpha)) 0 0 0 2px,inset rgba($primary, var(--aa-primary-color-alpha)) 0 0 0 2px;
    }
  }
}

.search-widget__group {
  position: relative;
}

.search-widget__input-right {
  padding-right: 50px ;
}

.search-widget__btn {
  position: absolute;
  right: 0;
  top: 0;
}

.aa-Panel {
  z-index: $zindex-modal+1;

  .aa-ItemWrapper {
    display: flex;
    justify-content: stretch;
  }

  .aa-ItemLink {
    display: flex;
    width: 100%;
  }
}
