.l-footer {
  position: relative;
  color: $white;
  background-image: $gradient-dark-horizontal;

  .footer-flow {
    background-image: url("../img/flow-footer.png");
    background-repeat: no-repeat;
    padding-bottom: 20px;
  }

  .cms-page-link,
  .custom-page-link,
  .account-list a {
    color: $white;

    &:hover {
      color: $white;
    }
  }

  .newsletter {
    display: flex;
    flex-wrap: wrap;
    padding: 40px 0;
    border-bottom: 1px solid rgba($white, .2);

    @include media-breakpoint-mobile {
      flex-direction: column;
      justify-content: center;
      text-align: center;
    }

    .main-title {
      margin-bottom: 0;
      font-family: $font-family-headings;
      font-size: 2.875rem;

      @include media-breakpoint-mobile {
        font-size: 2.25rem;
      }
    }

    .subtitle {
      margin-bottom: 0;
      font-family: $font-family-headings;
      font-size: 1.25rem;
    }

    .cta {
      display: flex;
      align-items: center;
      margin-left: auto;

      @include media-breakpoint-mobile {
        justify-content: center;
        margin-top: 20px;
        margin-left: 0;
      }
    }
  }

  .row-navigation {
    padding: 40px 0;

    .logo {

      @include media-breakpoint-mobile {
        display: flex;
        justify-content: center;
      }
    }

    .link-block {
      @extend .col-lg-2;
      @extend .col-md-4;
      @extend .col-12;

      ul {
        margin-top: 30px;
        margin-bottom: 0;
        list-style: disc;

        @include media-breakpoint-mobile {
          list-style: none;
          text-align: center;
        }
      }

      li {
        line-height: 2;
      }

      a {
        display: inline-block;
        font-size: .875rem;
        border-bottom: 1px solid transparent;
        transition: border-bottom $anim-speed $anim-curve;

        &:hover,
        &:focus,
        &:active {
          border-bottom: 1px solid $white;
        }
      }
    }
  }

  .row-solina {

    .col-logo-solina {
      display: flex;
      align-items: center;

      @include media-breakpoint-mobile {
        justify-content: center;
      }
    }

    .logo-solina {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-end;

      @include media-breakpoint-mobile {
        flex-direction: column;
        align-items: center;
        margin-bottom: 30px;
      }

      .text {
        font-size: .812rem;
        text-transform: uppercase;
        margin-right: 10px;
        margin-bottom: 0;
        line-height: 1;

        @include media-breakpoint-mobile {
          margin-right: 0;
          margin-bottom: 5px;
        }
      }

      a {
        color: $white;
      }
    }

    .local-brands {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      @include media-breakpoint-mobile {
        flex-direction: column;
      }

      .text {
        font-size: .875rem;
        margin-right: 40px;

        @include media-breakpoint-mobile {
          margin-right: 0;
        }
      }

      .brand-block {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 5px 10px;
        width: 125px;
        height: 65px;

        &:not(:last-of-type) {
          margin-right: 40px;

          @include media-breakpoint-mobile {
            margin-right: 0;
          }
        }

        img {
          max-height: 100%;
        }
      }
    }

    hr {
      border-top: 1px solid rgba($white, .2);
    }
  }

  .row-footer-bottom {

    .link-block {
      @extend .col-lg-9;
      @extend .col-12;

      ul {

        @include media-breakpoint-mobile {
          display: flex;
          flex-direction: column;
          align-items: center;
        }
      }

      li {
        display: inline-block;
        font-size: .875rem;

        &:not(:last-of-type) {

          &:after {
            content: "|";
            margin: 0 10px;

            @include media-breakpoint-mobile {
              display: none;
            }
          }
        }
      }

      a {
        display: inline-block;
        border-bottom: 1px solid transparent;
        transition: border-bottom $anim-speed $anim-curve;

        @include media-breakpoint-mobile {
          line-height: 2;
        }

        &:hover,
        &:focus,
        &:active {
          border-bottom: 1px solid $white;
        }
      }
    }

    .social-sharing {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      @extend .col-lg-3;
      @extend .col-12;

      @include media-breakpoint-mobile {
        flex-direction: column;
      }

      .text {
        font-size: .875rem;
        margin-right: 15px;

        @include media-breakpoint-mobile {
          margin-right: 0;
          margin-bottom: 5px;
        }
      }

      .links {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
      }
    }
  }

  .to-top {
    position: absolute;
    top: 0;
    right: 30px;

    button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 57px;
      height: 57px;
      border: 0;
      border-radius: 100%;
      background-color: $white;
      color: $primary;
      transform: translateY(-50%);
      transition: color $anim-speed $anim-curve, background-color $anim-speed $anim-curve;

      &:hover {
        background-color: $essential-brown;
        color: $white;
      }
    }
  }
}
