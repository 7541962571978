.banner-with-image {
  padding: 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  &.no-description {

    .banner-title {
      margin-bottom: 0;
    }

    .text-content {
      padding: 50px 0 65px;
    }
  }

  .background-gradient {
    position: relative;
    background: linear-gradient(145deg, rgba($black, .85) 0%, transparent 50%),
                linear-gradient(65deg, rgba($color-main, 1) 0%, transparent 50%);
  }

  .flow {
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: url('../img/flow-home-banner.png');
    background-repeat: no-repeat;
    background-size: 380px;
    background-position: calc(50% - 320px) top;

    @include media-breakpoint-down(xl) {
      background-position: calc(50% - 200px) top;
    }

    @include media-breakpoint-down(md) {
      background-position: calc(100% + 150px) top;
    }

    @include media-breakpoint-down(xs) {
      background-position: -300px -25px;
    }
  }

  .background-image {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  .text-content {
    position: relative;
    padding-top: 50px;
    max-width: 400px;
    height: 430px;

    @include media-breakpoint-mobile {
      height: 300px;
    }
  }

  .description {
    color: $white;
    font-size: 1.125rem;
    margin-bottom: 30px;
  }
}

.banner-full-bg {
  background-image: linear-gradient(118deg, $color-main, $primary 68%, $secondary);
  width: 100%;
  height: 166px;
  margin-bottom: -150px;
  position: relative;
  z-index: -1;

  @include media-breakpoint-mobile {
    display: none;
  }

  .full-bg-img {
    background-image: url("../img/banner-flow.png");
    background-repeat: no-repeat;
    background-position: right;
    height: 100%;
  }

  .white-border {
    background-color: $white;
    width: calc(100% + 40px);
    height: 100px;
    display: block;
    position: relative;
    bottom: -67px;
    left: -20px;
  }
}
