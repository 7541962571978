.btn {
  text-transform: uppercase;
  transition: color $anim-speed $anim-curve, border $anim-speed $anim-curve,
    background-color $anim-speed $anim-curve, box-shadow $anim-speed $anim-curve;

  &.btn-picto-left {
    display: flex;
    align-items: center;

    svg {
      margin-right: 10px;
      transition: color $anim-speed $anim-curve;
    }
  }

  &.btn-header {

    &:hover,
    &:focus,
    &:active {
      background-color: $primary;
      color: $white;
      border: 1px solid $primary;

      svg {
        color: $white;
      }
    }

    svg {
      color: $primary;
    }
  }

  // Colors
  &.btn-primary {
    background-color: $primary;
    border: 1px solid $primary;
    color: $white;

    &:hover,
    &:focus,
    &:active {
      background-color: $white !important;
      color: $primary !important;
      box-shadow: $btn-box-shadow !important;
      border-color: $primary !important;
    }
  }

  &.btn-secondary {
    background-color: $secondary;
    border: 1px solid $secondary;
    color: $white;

    &:hover,
    &:focus,
    &:active {
      background-color: $secondary-hover;
      border: 1px solid $secondary-hover;
    }
  }

  &.btn-essential-brown {
    background-color: $essential-brown;
    border: 1px solid $essential-brown;
    color: $white;

    &:hover,
    &:focus,
    &:active {
      background-color: $essential-brown-hover;
      border: 1px solid $essential-brown-hover;
    }
  }

  // Sizes
  &.btn-big {
    padding: 15px 30px;
  }

  // Outline
  &.btn-outline {
    background-color: transparent;

    &.btn-outline-white {
      border: 1px solid $white;
      color: $white;

      &:hover,
      &:focus,
      &:active {
        background-color: $white;
      }

      &.hover-primary {

        &:hover,
        &:focus,
        &:active {
          color: $primary;
        }
      }
    }

    &.btn-outline-primary {
      font-size: 0.812rem;
      border: 1px solid $primary;
      color: $primary;
      box-shadow: $btn-box-shadow;
      font-weight: 600;

      &:hover,
      &:focus,
      &:active {
        color: $white;
        background-color: $primary;

      }
    }
  }

  &.btn-outline-essential-brown {
    background-color: transparent;
    border: 1px solid $essential-brown;
    color: $essential-brown;

    &:hover,
    &:focus,
    &:active {
      background-color: $essential-brown;
      color: $white;
    }
  }
}

.read-more-link {
  display: inline-block;
  margin-top: 20px;
  font-size: .875rem;
  color: $primary;
  text-transform: uppercase;
  border-bottom: 1px solid $essential-yellow;
  @extend .outline;
  @extend .outline-primary;
}
