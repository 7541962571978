.form-builder {
  font-family: $font-family-main;

  input,
  select,
  textarea {
    @extend .outline;
    @extend .outline-secondary;
  }

  .itemfield {
    margin-bottom: 10px;
  }

  .formbuilder_group {
    display: flex;
    flex-wrap: wrap;
  }

  .gformbuilderpro_form {

    label {

      &.required_label {

        &::after {
          left: 3px;
        }
      }
    }
  }

  .gformbuilderpro_content {

    label {
      font-size: .875rem;
      color: $grey-text;
      margin-bottom: .2rem;
    }
  }

  input[type="checkbox"],
  input[type="radio"] {
    margin-top: 4px;
    margin-right: 10px;
  }

  .imagethumb__box {

    input[type="checkbox"],
    input[type="radio"] {
      margin-top: 0;
      margin-right: 0;
    }
  }

  .checkbox_box,
  .radio_box {

    p {
      margin-bottom: 0;
    }
  }

  .form-control {

    &.color {
      width: unset;
      height: unset;
      box-shadow: none;
    }

    &.datepicker {
      width: unset;
    }
  }

  input[type="file"] {
    background-color: transparent;
    border: 0;
    padding: 0;
    box-shadow: none;
  }

  .minicolors {

    .minicolors-swatch {
      top: 7px;
      left: 7px;
    }
  }

  .imagethumb_input_wp {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .imagethumb__item_content {

    label {
      margin-bottom: 0;
    }
  }

  .spinner_value {
    width: 75px;
  }

  button[type="submit"] {
    @extend .btn, .btn-primary, .outline, .outline-primary, .btn-big;

  }

  .onoffswitch-inneryes {
    background-color: $secondary;
  }

  .ui-widget-content {
    border: 0;
  }

  .ui-widget-header {
    background: $secondary;
  }

  .ui-state-hover,
  .ui-state-focus {
    background: $white;
  }

  .ui-widget-content,
  .ui-widget-header {

    .ui-state-default {
      background: $secondary;
    }

    .ui-state-hover,
    .ui-state-focus {
      background: $secondary-hover;
    }
  }

  .ui-slider-horizontal .ui-slider-handle {
    top: -.2em
  }

  .success_box {
    padding: 0;

    border: 0;
    background-color: transparent;
  }

  .alert {
    margin-left: 15px;

    ol {
      padding-left: 15px;
      margin-bottom: 0;
    }

    .close {
      margin-left: 10px;
    }
  }
}
