#search {

  #content-wrapper {
    margin-bottom: 50px;
  }

  .products-selection {
    background-color: $grey-bg;

    @include media-breakpoint-mobile {
      padding: 10px;
    }
  }

  .page-content {
    padding: 1.25rem 0;

    #search_widget {

      .close-row {
        display: none !important;
      }
    }
  }
}

#module-nps_elastique-search {
  background-color: $grey-bg;

  .search-top {
    padding: 30px 0;
    background-color: $grey-bg-dark;

    .search-widget {
      width: 100%;
      max-width: 620px;

      .close-row {
        display: none !important;
      }

      .input-search {
        padding: 10px 90px 10px 25px;
        border-bottom: 0;
        border-radius: 55px;
        font-size: 1rem;
        height: 68px;
      }

      .btn-submit {
        color: $primary;
        border-radius: 0;
        right: 20px;

        &:hover,
        &:focus,
        &:active {
          color: $primary-hover;
        }
      }
    }
  }


  .main-title-page {
    font-size: 1.5rem;
    font-weight: $font-semi-bold;
  }

  .tab-content {
    padding-top: 2rem;
  }

  .pages {

    .page-item {
      margin-bottom: 1rem;
      border: 1px solid $grey-border;
      width: 100%;
      max-width: 720px;

      a {
        display: flex;
        align-items: center;
        padding: 10px 20px;

        span {
          margin-right: 30px;
        }

        svg {
          flex-shrink: 0;
          margin-left: auto;
        }
      }
    }
  }

  .search-results {
    @extend .row;
    align-items:stretch;

    .search-card {
      @extend .col-6;
      margin-bottom: 30px;
      padding-top: 30px;
      padding-bottom: 30px;

      .result-type {
        position: absolute;
        top: 0;
        left: 0;
        background-color: $primary;
        font-size: .875rem;
        color: $white;
        padding: 2px;
      }
    }

    @include media-breakpoint-desktop {

      .search-card {
        @include make-col(4);
      }

      .search-card {
        @include make-col(3);
      }
    }

    @media (max-width: 320px) {
      .search-card {
        @include make-col(12);
      }
    }
  }

  #content-wrapper {
    margin-bottom: 50px;
  }

  .products-selection {
    background-color: $grey-bg;

    @include media-breakpoint-mobile {
      padding: 10px;
    }
  }

  .page-content {
    padding: 1.25rem 0;

    &.page-not-found {
      padding: 1.25rem 15px;

      form {
        margin: 0;
      }
    }

    #search_widget {

      .close-row {
        display: none !important;
      }
    }
  }
}
