.left-column .search_filters {
  border: 0;

  .filter-block-title {
    color: $grey-text-light;
    font-size: 1.125rem;
  }

  .cta {
    margin-top: 30px;
  }
}

@include media-breakpoint-mobile {

  .facet__block:not(.show) {
    padding-bottom: 0;
  }
}

.facet__block {

  &:first-of-type {
    border-top: 1px solid $grey-border;
  }

  &:not(:last-child) {
    border-bottom: 1px solid $grey-border;
  }

  .collapse--facet {
    margin-bottom: 15px;
  }

  .color__label {
    color: $grey-text;
  }

  .magnitude {
    color: $essential-brown;
  }
}

.facet__header {
  position: relative;

  a {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0;

    &:hover {

      .facet__title {
        color: $essential-brown;
      }
    }

    &[aria-expanded="true"] {
      transform: none;

      .minus {
        display: block;
      }

      .plus {
        display: none;
      }
    }

    .facet__title {
      font-size: 1.125rem;
      color: $black;
      margin-bottom: 0;
      white-space: nowrap;
      text-overflow: ellipsis;
      transition: color $anim-speed $anim-curve;
    }

    .minus {
      display: none;
    }
  }
}


.left-column .search_filters {
  @extend .card-block;
}

.custom-color{
  &.custom-control{
    padding-left: 0;
    margin-bottom: .25rem;
  }
  .custom-control-label:before,
  .custom-control-label:after
  {
    display:none
  }
  .color{
    margin-right: .25rem;
    border: none;
  }
  .color__label{
    //dis
    line-height: 1.25;
    display: inline-block;
  }
  &.custom-control--active{
    .color{
      border: 2px solid black;
    }
  }
}

.btn--clearfilter{
  margin-bottom: $spacer;
}

.active-filter__title{
  margin-bottom: $spacer/2;
  font-weight: bold;
}

.active_filters__list {
  margin-bottom: 20px;

  .btn {
    margin-bottom: $spacer/4;
    font-size: .875rem;
    text-transform: none;
    color: $essential-brown;
    border: 1px solid $grey-border;

    &.btn-clearfilter {
      color: $white;
      background-color: $essential-brown;
      border: 1px solid $essential-brown;
    }
  }
}

.active_filters{
  background-color: $bg-dark;
  padding: $spacer $spacer ($spacer*.75);
  margin-bottom: $spacer*1.25;
}

.faceted-overlay {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: rgba(25, 25, 25, 0.5);
  z-index: $zindex-modal+1;
}

.faceted-overlay .overlay__inner {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: absolute;
}

.faceted-overlay .overlay__content {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

.faceted-overlay .spinner {
  width: 75px;
  height: 75px;
  display: inline-block;
  border-width: 2px;
  border-color: rgba(255, 255, 255, 0.05);
  border-top-color: #fff;
  animation: spin 1s infinite linear;
  border-radius: 100%;
  border-style: solid;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

#search_filters .ui-slider-horizontal .ui-slider-handle {
  margin-left: -1px;
  cursor: pointer;
}

#search_filters .ui-widget-header {
  background: #555;
}

#search_filters .ui-slider .ui-slider-handle {
  top: -.45em;
  width: 0.4em;
  background: #fff;
  border: 1px solid #555;
}

#search_filters .ui-slider-horizontal {
  height: .4em;
}
