#cms {

  #content-wrapper {
    margin-bottom: 50px;
  }

  .page-wrapper {
    padding: 0;
    background-color: transparent;
    border: 0;
  }

  .page-content {
    padding: 0;
  }
}
