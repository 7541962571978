.axeptio-iframe {
  position: relative;
  background-color: $grey-bg;

  [data-hide-on-vendor-consent] {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &.video {
    width: 100%;
    aspect-ratio: 16/9;
  }
}
