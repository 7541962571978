.card-featured-category-colored-wrapper {
  padding-top: 30px;
  padding-bottom: 30px;
  height: 100%;

  @include media-breakpoint-mobile {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.card-featured-category-colored {
  position: relative;
  height: 100%;
  background-image: $gradient-dark;
  color: $white;
  overflow: hidden;
  padding: 50px 50px 30px;

  .flow {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-image: url("../img/flow-highlight-border-right.png");
    background-repeat: no-repeat;
    background-position: top right;
  }

  .content {
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100%;
  }

  .content-title {
    color: $white;
  }

  .description {
    margin-bottom: 30px;
  }

  .cta {
    margin-top: auto;
    text-align: center;

    a {
      display: inline-block;
      width: 100%;
    }
  }
}
