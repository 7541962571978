.page-standard {

  .top-content {
    margin-bottom: 100px;

    @include media-breakpoint-mobile {
      margin-bottom: 75px;
    }

    .introduction {
      font-size: 1.25rem;
      font-weight: $font-bold;
    }
  }
}

.vb-block {
  padding: 55px 0;

  &:first-of-type {
    padding-top: 0;
  }

  &.vb-banner-block {
    padding: 0;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    .background-gradient {
      position: relative;
      background: linear-gradient(145deg, rgba($black, .85) 0%, transparent 50%),
                  linear-gradient(65deg, rgba($secondary, 1) 0%, transparent 50%);
    }

    .flow {
      position: absolute;
      width: 100%;
      height: 100%;
      background-image: url('../img/flow-home-banner.png');
      background-repeat: no-repeat;
      background-size: contain;
      background-position: calc(50% - 320px);

      @include media-breakpoint-down(xl) {
        background-position: calc(50% - 200px);
      }

      @include media-breakpoint-down(md) {
        background-position: calc(100% + 150px);
      }

      @include media-breakpoint-down(xs) {
        background-position: -300px -25px;
      }
    }

    .background-image {
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }

    .text-content {
      position: relative;
      padding: 100px 0;
      max-width: 450px;
    }

    .description {
      color: $white;
      font-size: 1.125rem;
      margin-bottom: 30px;
    }
  }

  &.vb-highlight-block {
    position: relative;
    padding-bottom: 95px;

    @include media-breakpoint-mobile {
      padding-bottom: 55px;
    }

    &.bg-darker {
      background-color: $grey-bg;
      overflow: hidden;
    }

    .text-content {

      @include media-breakpoint-mobile {
        margin-bottom: 0;
        margin-top: 30px;
      }
    }

    &.img-left {

      .row-highlight-block {

        @include media-breakpoint-mobile {
          flex-direction: column;
        }
      }

      .flow-border {
        background-image: url("../img/flow-highlight-border-left.png");
        background-position: top left;
      }

      .flow {
        background-image: url("../img/flow-full-left.png");
        background-position: top left;
        left: -130px;
        right: auto;
      }

      .square-background {
        right: -40px;
        left: auto;
      }

      .text-content {
        padding-right: 0;
        padding-left: 100px;

        @include media-breakpoint-mobile {
          padding-left: 0;
        }
      }
    }

    &.img-right {

      .row-highlight-block {

        @include media-breakpoint-mobile {
          flex-direction: column-reverse;
        }
      }
    }

    .flow-border {
      position: absolute;
      background-image: url("../img/flow-highlight-border-right.png");
      background-position: top right;
      background-repeat: no-repeat;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      @include media-breakpoint-mobile {
        display: none;
      }
    }

    .flow {
      position: absolute;
      background-image: url("../img/flow-full.png");
      background-repeat: no-repeat;
      background-position: top right;
      background-size: contain;
      width: 100%;
      height: calc(100% + 275px);
      top: -50px;
      right: -130px;

      @include media-breakpoint-mobile {
        display: none;
      }
    }

    .square-background {
      position: absolute;
      background-color: $tertiary;
      height: 80%;
      width: 300px;
      bottom: -40px;
      left: -40px;

      @include media-breakpoint-mobile {
        display: none;
      }
    }

    .text-content {
      padding-right: 100px;

      @include media-breakpoint-mobile {
        padding-right: 0;
      }
    }

    .image-content {
      position: relative;

      img {
        position: relative;
        width: 100%;
        max-height: 440px;
        object-fit: cover;
      }
    }
  }

  &.vb-inspirations-block {

    .image {
      position: relative;
      margin-bottom: 30px;
      overflow: hidden;

      &:hover {

        img {
          transform: scale(1.05);
        }

        .hover-content {
          opacity: 1;
          transform: translateY(0);
        }
      }

      .hover-content {
        font-family: $font-family-headings;
      }

      img {
        width: 100%;
        height: 420px;
        object-fit: cover;
        transform: scale(1);
        transition: transform $anim-speed-slow $anim-curve;

        @include media-breakpoint-down(xl) {
          height: 310px;
        }

        @include media-breakpoint-down(lg) {
          height: 270px;
        }

        @include media-breakpoint-down(md) {
          height: 230px;
        }

        @include media-breakpoint-down(sm) {
          height: auto;
        }
      }

      .hover-content {
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding: 20px;
        width: 100%;
        height: 100%;
        bottom: 0;
        left: 0;
        color: $white;
        text-align: center;
        font-size: 1.125rem;
        font-family: $font-family-headings;
        background-image: linear-gradient(0deg, rgba($black, .75) 0%, transparent 75%);
        transform: translateY(100%);
        opacity: 0;
        transition: transform $anim-speed-slow $anim-curve, opacity $anim-speed-slow $anim-curve;

        p {
          margin-bottom: 10px;
        }
      }

      a {
        color: $white;

        &::after {
          content: "";
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
        }
      }
    }
  }

  &.vb-double-text-block {

    .column {

      &:not(:last-of-type) {

        @include media-breakpoint-mobile {
          margin-bottom: 30px;
        }
      }
    }
  }

  &.vb-video-block {

    .video-container {
      max-width: 1005px;
      margin: 50px auto;

      iframe {
        width: 100%;
        aspect-ratio: 16 / 9;
      }

      .wysiwyg-content {
        margin-top: 15px;
        margin-bottom: 15px;
      }
    }
  }

  &.vb-cards-image-block {
    position: relative;
    background-color: $grey-bg;
    padding: 85px 0;

    .background-flow {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-image: url("../img/flow-block-cards.png");
      background-repeat: no-repeat;
      background-position: left center;
      background-size: contain;

      @include media-breakpoint-mobile {
        display: none;
      }
    }

    .card-image-simple {
      padding: 0;
      border: 0;
      background-color: transparent;

      @include media-breakpoint-mobile {
        margin-bottom: 35px;
      }

      .image {
        height: 300px;
        margin-bottom: 20px;

        @include media-breakpoint-down(xl) {
          height: 220px;
        }

        @include media-breakpoint-down(lg) {
          height: 190px;
        }

        @include media-breakpoint-down(md) {
          height: 400px;
        }

        @include media-breakpoint-down(sm) {
          height: 320px;
        }

        @include media-breakpoint-down(xs) {
          height: 190px;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }

  &.vb-wide-image-block {
    padding: 0;
    margin-bottom: 50px;

    .bg-image {
      height: 400px;
      background-repeat: no-repeat;
      background-size: cover;
      transform: translateX(-20px);

      @include media-breakpoint-mobile {
        height: 300px;
      }

      &.aos-animate {
        transform: translateX(0);
      }
    }
  }

  &.vb-quotation-block {

    .quote {
      font-family: $font-family-headings;

      p {
        font-size: 1.5rem;

        &:first-child,
        &:last-child {

          &::before,
          &::after {
            font-size: 2.875rem;
            line-height: 0;

            @include media-breakpoint-mobile {
              font-size: 2rem;
            }
          }
        }

        &:first-child {

          &::before {
            content: "“";
            margin-right: 5px;
          }
        }


        &:last-child {

          &::after {
            content: "”";
            margin-left: 5px;
          }
        }
      }
    }

    .author {
      font-size: 1.25rem;
      font-style: italic;
    }
  }

  &.vb-form-block {

    .background {
      background-color: $grey-bg;
      padding: 20px;
    }

    .capcha_box {

      .col-md-4,
      .col-md-8 {
        flex: 0 0 100%;
        max-width: 100%;
      }

      label {

        &::after {
          content: '*';
          color: red;
          font-size: 14px;
          position: relative;
          line-height: 12px;
          left: 3px;
        }
      }
    }
  }

  &.vb-featured-categories {
    padding-bottom: 25px;

    + .vb-featured-categories:not(.has-headline) {
      margin-top: -60px;
    }

    .featured-category {

      @include media-breakpoint-mobile {
        margin-top: -30px;
      }
    }

    .section-title {
      margin-bottom: 20px;

      @include media-breakpoint-mobile {
        margin-bottom: 50px;
      }
    }

    .col-card-colored {

      @include media-breakpoint-mobile {
        order: 2;
      }
    }

    .col-slider {

      @include media-breakpoint-mobile {
        order: 1;
      }
    }
  }

  &.vb-blog-category-block {

    &.grey-template {
      background-color: $grey-bg;
      background-image: url("../img/flow-full-left.png");
      background-repeat: no-repeat;
      background-position: -100px top;

      @include media-breakpoint-mobile {
        background-image: none;
      }
    }

    .row-cards-blog {
      justify-content: center;
    }

    .subtitle {
      margin-top: -30px;
      margin-bottom: 30px;
      text-align: center;
    }

    .cta-to-blog {
      margin-top: 30px;
      text-align: center;
    }
  }

  &.vb-last-recipe-block {

    &.grey-template {
      background-color: $grey-bg;
      background-image: url("../img/flow-full.png");
      background-repeat: no-repeat;
      background-position: calc(100% + 100px) top;

      @include media-breakpoint-mobile {
        background-image: none;
      }
    }
  }
}
