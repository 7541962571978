#contact {

  #wrapper {
    margin-bottom: 50px;
  }

  .form-title {
    padding-bottom: .5rem;
  }

  .page-content {
    padding: 0;
  }

  .page-wrapper {
    border: 0;
  }

  .banner-with-image {

    .flow {
      background-position: -200px top;

      @include media-breakpoint-down(xl) {
        background-position: -200px top;
      }

      @include media-breakpoint-down(md) {
        background-position: -300px top;
      }

      @include media-breakpoint-down(xs) {
        background-position: -300px -25px;
      }
    }
  }
}
