@include media-breakpoint-desktop {

  .supermenu {

    .submenu-wrapper {
      position: absolute;
      width: 100%;
      left: 0;
      top: 100%;
      background-color: $grey-bg-dark;
      box-shadow: $header-box-shadow;
      transition: all $anim-speed $anim-curve;
      transition-delay: .3s;
    }

    .submenu-main {
      display: flex;
      min-height: 350px;
      height: 100%;

      > .menu-sub__list {
        width: 100%;
        padding: 50px 50px 50px 15px;
        column-count: 2;

        > .menu__item--sub {
          width: calc(100% - 10px);
          max-width: 300px;
          font-size: .875rem;
          font-weight: $font-bold;
          text-transform: uppercase;

          a {
            position: relative;
            display: inline-block;
            padding-bottom: 15px;
            color: $color-main;

            &:hover {
              color: $essential-brown;

              &::after {
                background-color: $primary;
              }
            }

            &::after {
              content: "";
              position: absolute;
              width: 100%;
              height: 1px;
              top: calc(100% - 15px);
              left: 0;
              background-color: transparent;
              transition: background-color $anim-speed $anim-curve;
            }
          }
        }
      }
    }

    .image-category {
      flex-shrink: 0;
      padding-right: 50px;

      .img-bg {
        width: 100%;
        height: 100%;
        background-image: url("../img/category-default.png");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        transition: background-image $anim-speed $anim-curve;
        transition-delay: .25s;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: opacity $anim-speed $anim-curve;

        &.hide {
          opacity: 0;
        }
      }
    }
  }
}
