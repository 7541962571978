.page-product {

  #content-wrapper {
    margin-bottom: 50px;

    @include media-breakpoint-mobile {
      margin-top: 15px;
    }
  }

  .main-title {
    text-transform: lowercase;
    font-family: $font-family-headings;
    font-size: 2.875rem;
    letter-spacing: -.5px;
    margin-bottom: 10px;

    &::first-letter {
      text-transform: uppercase;
    }

    @include media-breakpoint-mobile {
      font-size: 2.5rem;
    }
  }

  .product-category {
    font-family: $font-family-headings;
    font-size: 1.625rem;
  }

  .main-info {
    padding-top: 30px;

    @include media-breakpoint-desktop {
      padding: 30px;
    }
  }

  .products-imagescover {
    border: 1px solid $grey-border;
  }

  .tabs {

    .nav-tabs {
      justify-content: flex-start;
      font-size: 1rem;

      .nav-link {
        color: $grey-text-dark;
        font-weight: 400;

        &.active {
          color: $essential-brown;
          border-color: transparent transparent $essential-brown;
          font-weight: 700;

          @include media-breakpoint-mobile {
            color: $white;
            border-color: $primary;
          }
        }
      }
    }

    .product-conditionning-table {
      width: 100%;

      p {
        margin-bottom: 10px;
      }

      ul {
        list-style: disc inside;
      }
    }
  }

  .conditionning {

    .item {
      margin-bottom: 0;
    }
  }

  .product-description,
  .product-description-short,
  .product-instructions {
    color: $grey-text;
  }

  .product-description,
  .product-description-short,
  .product-instructions,
  .product-description span {
    font-size: 1rem !important;
    font-family: $font-family-main !important;
    line-height: 26px;
  }

  .product-description-icons {
    margin-top: 30px;

    .item-icon {
      width: 60px;
      height: 60px;
    }

    img {
      max-width: 100%;
      max-height: 100%;
      margin: auto;
    }
  }

  .product-btn-download {
     padding: .75rem;
  }

  .product-cta-buy {

    @include media-breakpoint-desktop {
      max-width: 330px;
    }

    .btn {
      width: 100%;
    }
  }

  .accordion-product {

    .card {
      border: 0;
    }

    .card-header {
      background-color: $grey-bg;
      border: 0;
      border-bottom: 2px solid $white;

      .btn-accordion {
        width: 100%;
        border: 0;
        text-align: left;
        padding: 20px 0;
        background-color: $grey-bg;
        display: block;
        font-size: 2.25rem;
        font-family: $font-family-headings;
        color: $color-main;
        position: relative;

        @include media-breakpoint-mobile {
          font-size: 1.8rem;
          padding: 10px 0;
        }

        &::after {
          content: "+";
          position: absolute;
          right: 0;
          font-weight: 700;
          color: $essential-brown;
        }

        &[aria-expanded=true] {

          &::after {
            content: "-";
          }
        }

        &:focus {
          outline: 1px dotted $primary;
        }
      }
    }

    .card-body {
      color: $grey-text;;
    }
  }

  .product-features-group {
    border-bottom: 1px solid $grey-border;
    padding: 10px 0;
    max-width: 1000px;
  }

  .product-nutritional-table,
  .product-efficiency-table {
    width: 100%;

    @include media-breakpoint-desktop {
      min-width: 550px;
      width: auto;
    }

    th {
      background-color: $pro-blue;
      font-size: 1.25rem;
      color: $black;
      font-weight: 500;
    }

    th,
    td {
      padding: 13px;
    }

    td {
      border-bottom: 1px solid $grey-border;
    }
  }

  .product-efficiency-table {
    width: 100%;
  }

  .product-flags-cover-container {

    .product-flags {
      position: absolute;
      top: 0;
    }
  }

  .modal-backdrop {
    background-color: $white;
  }

  .product-recipes-listing {
    margin: 50px 0;
  }
}
