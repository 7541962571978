.language-selector-wrapper {

  .dropdown-toggle {
    display: flex;
    align-items: center;
    text-transform: uppercase;
    font-size: .875rem;

    &::after {
      margin-left: 10px;
    }
  }

  .dropdown-menu {
    padding: 5px;

    .dropdown-item {
      text-transform: uppercase;

      @include media-breakpoint-only(lg) {
        font-size: .875rem;
      }

      &:hover {
        background-color: $grey-bg;
      }

      &.active,
      &:active {
        background-color: $primary;
      }

      &.active {
        box-shadow: none !important;
      }

      &:not(:last-of-type) {
        margin-bottom: 5px;
      }
    }
  }
}
