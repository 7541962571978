#module-solina_brochures-listing {
  overflow-x: hidden;
}

.brochures-listing {

  > .container {
    position: relative;
  }

  .flow-bg {
    background-image: url("../img/flow-full.png");
    background-repeat: no-repeat;
    background-position: calc(100% + 40px) 0;
    width: 472px;
    height: 886px;
    position: absolute;
    right: -5px;
    top: -38px;
    opacity: .25;
  }

  .main-description {
    margin-bottom: 20px;
    font-size: 1.25rem;
    font-weight: $font-bold;
  }

  .card-brochure {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .card-brochure-img-container {
    margin-bottom: 20px;
    border: 1px solid $grey-border;

    @include media-breakpoint-up(md) {
      height: 220px;
    }

    @include media-breakpoint-only(lg) {
      height: 184px;;
    }

    @include media-breakpoint-up(xxl) {
      height: 310px;
    }

    img {
      width: 100%;
      max-width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .card-brochure-content {
    height: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .card-brochure-title {
    font-family: $font-family-headings;
    font-size: 1.625rem;
    letter-spacing: -.17px;

    @include media-breakpoint-up(sm) {
      min-height: 78px;
    }
  }

  .card-brochure-description {
    font-size: .875rem;
    color: $grey-text;
    line-height: 24px;
  }

  .card-brochure-link {
    text-transform: uppercase;
    font-size: .875rem;
    margin-top: auto;
    font-weight: 500;

    svg {
      margin-left: 5px;
      color: $black;
      padding-bottom: 5px;
    }

    &::after {
      content: "";
      height: 1px;
      width: 50%;
      background-color: $primary;
      display: block;
      transition: width 1s $anim-curve-links;
    }

    &:hover {

      &::after {
        width: 100%;
      }

    }
  }

  .brochures-pagination {

    .next svg  {
      transform: rotate(180deg);
    }
  }
}
