.breadcrumb-out-banner,
.breadcrumb-banner-dark {

  .breadcrumb {
    background-color: transparent;
    margin: 0;
    font-size: .875rem;

    > .breadcrumb-item {
      display: inline-block;
      font-weight: $font-bold;

      + .breadcrumb-item:before {
        content: ">";
        padding: 0 5px 0 1px;
        font-weight: $font-regular;
      }
    }

    a {
      font-weight: $font-regular;
    }
  }
}

.breadcrumb-banner-dark {

  .breadcrumb {
    padding: .7rem 0;
    color: $white;

    > .breadcrumb-item {

      &.active {
        color: $white;
      }

      + .breadcrumb-item:before {
        color: $white;
      }
    }

    a {
      color: $white;
    }
  }
}

.breadcrumb-out-banner {

  .breadcrumb {
    padding: 30px 0;
    color: $grey-text;

    > .breadcrumb-item {

      &.active {
        color: $grey-text;
      }

      + .breadcrumb-item:before {
        color: $grey-text;
      }
    }

    a {
      color: $grey-text;
    }
    font-weight: 700;
  }
}
